import { ProductModel } from '@/models/product.model'
import { OrderHeadModel } from '@/views/order/model/order-head.model'
import { SupplierModel } from '@/models/supplier.model'

export class OrderSpecModel {
  constructor(request = {}) {
    this.id = request?.id
    this.order = request?.order
    this.int_order = request?.int_order
    this.product = new ProductModel(request?.product)
    this.sum = request?.sum
    this.supply_date = request.supply_date ? new Date(request?.supply_date) : null
    this.price = request?.price
    this.retail_price = request?.retail_price
    this.delta = request?.delta
    this.step_delta = request?.step_delta
    this.critical_level = request?.critical_level
    this.sold = request?.sold
    this.sold_rate = request?.sold_rate
    this.sold_period = request?.sold_period
    this.order_head = new OrderHeadModel(request?.order_head)
    this.supplier = request?.supplier ? new SupplierModel(request?.supplier) : null
  }

  get remainders() {
    let sum = 0
    this.product.remainders?.forEach((el) => (sum += el.value))
    return sum
  }

  setDelta = (val) => {
    this.delta = val
    this.sum = this.price * val
  }

  setPrice = (val) => {
    this.price = val
    this.sum = this.delta * val
  }

  setStepDelta = (val) => {
    this.step_delta = val
    this.setDelta(Math.round(this.delta / val) * val)
  }

  setCriticalLevel = (val) => {
    this.critical_level = val
    const delta =
      this.critical_level > this.delta + Math.max(this.remainders, 0)
        ? this.critical_level - Math.max(this.remainders, 0)
        : this.delta
    this.setDelta(delta)
  }

  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      delta: +this.delta,
      step_delta: this.step_delta ? +this.step_delta : null,
      critical_level: this.critical_level ? +this.critical_level : null,
      supplier: this.supplier?.id ?? null,
      price: this.price,
      sum: this.sum
    }
  }
}

export class OrderSpecPaginationModel {
  constructor(request = {}) {
    this.specification = request?.specification?.map((el) => new OrderSpecModel(el))
    this.total = request?.total
    this.order_sum = request?.order_sum
  }
}
