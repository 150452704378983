var render = function render(){
  var _vm$document, _vm$document2, _vm$document3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('e-button', {
    staticClass: "btn-filters cursor",
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.changeVisibleParams
    }
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    class: {
      rotate: _vm.visibleParams
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "b-action-buttons d-flex"
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.print
    }
  }, [_vm._v(" Печать ")]), !((_vm$document = _vm.document) !== null && _vm$document !== void 0 && _vm$document.fixed) ? _c('e-button', {
    staticClass: "cursor ml-2",
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить как черновик ")]) : _vm._e(), _c('e-button', {
    staticClass: "ml-2",
    attrs: {
      "size": "m",
      "disabled": _vm.loading,
      "loading": _vm.loading,
      "variant": "primary"
    },
    on: {
      "click": _vm.fixed
    }
  }, [!((_vm$document2 = _vm.document) !== null && _vm$document2 !== void 0 && _vm$document2.fixed) ? [_vm._v("Провести")] : _vm._e(), (_vm$document3 = _vm.document) !== null && _vm$document3 !== void 0 && _vm$document3.fixed ? [_vm._v("Редактировать")] : _vm._e()], 2)], 1)]), _c('e-collapse', {
    attrs: {
      "id": "collapse-filters",
      "show-button": false
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        var _vm$document4;

        return [_c('order-filters', {
          ref: "header_filters_incoming",
          attrs: {
            "fixed": (_vm$document4 = _vm.document) === null || _vm$document4 === void 0 ? void 0 : _vm$document4.fixed,
            "filter_params": _vm.document
          },
          on: {
            "change": _vm.change
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visibleParams,
      callback: function ($$v) {
        _vm.visibleParams = $$v;
      },
      expression: "visibleParams"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }