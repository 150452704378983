import { BranchModel } from '@/models/branch.model'
import { UserModel } from '@/models/user.model'
import { SupplierModel } from '@/models/supplier.model'
import { OrderSpecPaginationModel } from '@/views/order/model/order-spec.model'
import { StorageModel } from '@/models/storage.model'
import { EntityModel } from '@/models/entity.model'

export class OrderHeadModel {
  constructor(request = {}) {
    this.id = request?.id
    this.number = request?.number
    this.date = request?.date ? new Date(request.date) : null
    this.branch = request?.branch ? new BranchModel(request.branch) : null
    this.fixed = request?.fixed
    this.entity = new EntityModel(request?.entity)
    this.status = request?.status
    this.operation = request?.operation
    this.selectedEntities = request?.selectedEntities ?? []
    this.specifications = new OrderSpecPaginationModel(request?.specifications) //TODO specs
    this.supply_date = request?.supply_date ? new Date(request.supply_date) : null
    this.sum = request?.sum
    this.retail_sum = request?.retail_sum
    this.owner = request?.owner ? new UserModel(request.owner) : null
    this.supplier = request?.supplier ? new SupplierModel(request.supplier) : null
    this.comment = request?.comment
    this.selectedSuppliers = request?.selectedSuppliers ?? []
    this.selectedCategories = request?.selectedCategories ?? []
    this.storage = new StorageModel(request?.storage)
    this.recalculation_status = request?.recalculation_status
    this.filters = new OrderFiltersModel(request?.filters)
  }

  setComment = (val) => {
    this.comment = val
  }

  setEntity = (val) => {
    this.entity = val
  }

  setSelectedEntities = (val) => {
    this.selectedEntities = val
  }

  setStorage = (val) => {
    this.storage = new StorageModel(val)
  }

  setSupplier = (val) => {
    this.supplier = new SupplierModel(val)
  }

  setSupplyDate = (val) => {
    const date = new Date(val.split('.')[2], val.split('.')[1] - 1, val.split('.')[0])
    this.supply_date = new Date(date)
  }

  get input() {
    return {
      id: this.id,
      supply_date: this.supply_date,
      selectedSuppliers: this.selectedSuppliers?.length ? this.selectedSuppliers : null,
      selectedCategories: this.selectedCategories?.length ? this.selectedCategories : null,
      selectedEntities: this.selectedEntities?.length ? this.selectedEntities : null,
      supplier: this.supplier?.id ?? null,
      storage: this.storage?.id ?? null,
      comment: this.comment
    }
  }
}

export class OrderFiltersModel {
  constructor(request = {}) {
    this.categories = request?.categories
    this.suppliers = request?.suppliers
  }
}
