var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "order-document"
    }
  }, [_c('order-head', {
    staticClass: "mb-3",
    attrs: {
      "document": _vm.head,
      "loading": _vm.loading
    },
    on: {
      "fixed": _vm.fixDocument,
      "update-head": _vm.updateHead,
      "print": _vm.printDoc
    }
  }), _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('div', {
    staticClass: "b-table__content"
  }, [_c('div', {
    staticClass: "flex-card"
  }, [_c('documents-table-filter', {
    attrs: {
      "placeholder": "Поиск",
      "load_categories": true,
      "edit_fields": true
    }
  }), _c('resizable-table', {
    staticStyle: {
      "margin": "0 !important"
    },
    attrs: {
      "table_name": "order_spec",
      "default_fields": _vm.fields,
      "items": _vm.head.specifications.specification,
      "show_product_search": true,
      "busy": _vm.firstLoading
    },
    on: {
      "scroll-up": _vm.scrollUp,
      "scroll-down": _vm.scrollDown
    },
    scopedSlots: _vm._u([{
      key: "productSearch",
      fn: function () {
        var _vm$head$supplier$id, _vm$head, _vm$head$supplier;

        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "width": "200px"
          }
        }, [!_vm.head.fixed ? _c('product-search', {
          attrs: {
            "supplier": (_vm$head$supplier$id = (_vm$head = _vm.head) === null || _vm$head === void 0 ? void 0 : (_vm$head$supplier = _vm$head.supplier) === null || _vm$head$supplier === void 0 ? void 0 : _vm$head$supplier.id) !== null && _vm$head$supplier$id !== void 0 ? _vm$head$supplier$id : null,
            "document_type": "order",
            "document_head": _vm.head.id,
            "document": _vm.head
          },
          on: {
            "select_product": _vm.select_product
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          on: {
            "change": _vm.setSelected
          },
          model: {
            value: _vm.isAllSelected,
            callback: function ($$v) {
              _vm.isAllSelected = $$v;
            },
            expression: "isAllSelected"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_number",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.order) + " ")];
      }
    }, {
      key: "body_id",
      fn: function (_ref2) {
        var _vm$selected;

        var item = _ref2.item;
        return [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el.id === (item === null || item === void 0 ? void 0 : item.id);
            })
          },
          on: {
            "change": function (val) {
              return _vm.addProduct(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "text-left",
          staticStyle: {
            "width": "85%"
          }
        }, [_vm._v(" " + _vm._s(data.value.name) + " "), _c('div', {
          staticClass: "b-name article text-left"
        }, [_vm._v(" " + _vm._s(data.value.articul || 'Не указан') + " ")])])])];
      }
    }, {
      key: "body_delta",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('table-row-input', {
          ref: `delta${item.id}`,
          attrs: {
            "value": item.delta,
            "input_id": `delta${item.id}`,
            "fixed": _vm.head.fixed
          },
          on: {
            "handle-input": function (val) {
              return _vm.setDelta(val, item, true);
            },
            "input": function (val) {
              return _vm.setDelta(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_measurement",
      fn: function (_ref4) {
        var _item$product$measure, _item$product, _item$product$measure2;

        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((_item$product$measure = (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$measure2 = _item$product.measurement) === null || _item$product$measure2 === void 0 ? void 0 : _item$product$measure2.name) !== null && _item$product$measure !== void 0 ? _item$product$measure : '-') + " ")];
      }
    }, {
      key: "body_retailPrice",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ")];
      }
    }, {
      key: "body_sum",
      fn: function (_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ")];
      }
    }, {
      key: "body_remainders",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.getRemainders(item)) + " ")];
      }
    }, {
      key: "body_price",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('table-row-input', {
          ref: `price${item.id}`,
          attrs: {
            "value": item.price,
            "input_id": `price${item.id}`,
            "fixed": _vm.head.fixed
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, item, true);
            },
            "input": function (val) {
              return _vm.setPrice(val, item);
            }
          }
        })];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "hide_print": true,
      "items": _vm.selected
    },
    on: {
      "remove_items": _vm.removeItems
    }
  })], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }