<template>
  <section id="order-document">
    <!-- <breadcrumbs :extra="head" /> -->
    <order-head
      class="mb-3"
      :document="head"
      :loading="loading"
      @fixed="fixDocument"
      @update-head="updateHead"
      @print="printDoc"
    />
    <div class="b-table__wrapper">
      <div class="b-table__content">
        <div class="flex-card">
          <documents-table-filter
            placeholder="Поиск"
            :load_categories="true"
            :edit_fields="true"
          />
          <resizable-table
            style="margin: 0 !important"
            table_name="order_spec"
            :default_fields="fields"
            :items="head.specifications.specification"
            :show_product_search="true"
            :busy="firstLoading"
            @scroll-up="scrollUp"
            @scroll-down="scrollDown"
          >
            <template #productSearch>
              <div
                class="d-flex"
                style="width: 200px"
              >
                <product-search
                  v-if="!head.fixed"
                  :supplier="head?.supplier?.id ?? null"
                  document_type="order"
                  :document_head="head.id"
                  :document="head"
                  @select_product="select_product"
                />
              </div>
            </template>

            <template #head_id>
              <b-checkbox
                v-model="isAllSelected"
                @change="setSelected"
              />
            </template>

            <template #body_number="{ item }">
              {{ item.order }}
            </template>

            <template #body_id="{ item }">
              <div @click.stop>
                <b-form-checkbox
                  :checked="selected?.some((el) => el.id === item?.id)"
                  @change="(val) => addProduct(val, item)"
                />
              </div>
            </template>

            <template #body_product="data">
              <div class="d-flex justify-content-between align-items-center">
                <div
                  class="text-left"
                  style="width: 85%"
                >
                  {{ data.value.name }}
                  <div class="b-name article text-left">
                    {{ data.value.articul || 'Не указан' }}
                  </div>
                </div>
              </div>
            </template>

            <template #body_delta="{ item }">
              <table-row-input
                :ref="`delta${item.id}`"
                :value="item.delta"
                :input_id="`delta${item.id}`"
                :fixed="head.fixed"
                @handle-input="(val) => setDelta(val, item, true)"
                @input="(val) => setDelta(val, item)"
              />
            </template>

            <template #body_measurement="{ item }">
              {{ item.product?.measurement?.name ?? '-' }}
            </template>

            <template #body_retailPrice="{ value }">
              {{ formatPrice(value) }}
            </template>

            <template #body_sum="{ value }">
              {{ formatPrice(value) }}
            </template>

            <template #body_remainders="{ item }">
              {{ getRemainders(item) }}
            </template>

            <template #body_price="{ item }">
              <table-row-input
                :ref="`price${item.id}`"
                :value="item.price"
                :input_id="`price${item.id}`"
                :fixed="head.fixed"
                @handle-input="(val) => setPrice(val, item, true)"
                @input="(val) => setPrice(val, item)"
              />
            </template>
          </resizable-table>
          <table-navbar
            :hide_print="true"
            :items="selected"
            @remove_items="removeItems"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import { OrderHeadModel } from '@/views/order/model/order-head.model'
  import OrderHead from '@/views/order/components/OrderHead'
  import { http } from '@/http/http'
  import { mapActions } from 'vuex'
  import { print_html } from '@/utils/print'

  export default {
    name: 'Order',
    components: {
      OrderHead,
      TableNavbar,
      TableRowInput,
      ProductSearch,
      DocumentsTableFilter,
      ResizableTable
    },
    apollo: {
      OrderHead: {
        query: require('../gql/orderHead.graphql'),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.$route.params.id,
              all: true
            },
            pagination: this.pagination
          }
        },
        result({ data }) {
          if (!data) return
          this.getHead(data)
        }
      }
    },
    data() {
      return {
        loading: false,
        includedCategories: [],
        isAllSelected: false,
        selected: [],
        debounce: null,
        firstLoading: true,
        fields: [
          { key: 'id', label: '', checked: true, width: 55 },
          { key: 'order', label: '№', checked: true, width: 45 },
          {
            key: 'product',
            label: 'Наименование товара',
            checked: true,
            width: 250
          },
          { key: 'measurement', label: 'Ед. изм.', checked: true, width: 80 },
          {
            key: 'delta',
            label: 'Кол-во',
            checked: true,
            width: 90
          },
          {
            key: 'price',
            label: 'Закупочная цена, ₽',
            checked: true,
            width: 130
          },
          {
            key: 'sum',
            label: 'Закупочная сумма, ₽',
            checked: true,
            width: 135
          },
          {
            key: 'remainders',
            label: 'Остаток',
            checked: true,
            width: 90
          },
          {
            key: 'retailPrice',
            label: 'Розница текущая, ₽',
            checked: true,
            width: 130
          }
        ],
        head: new OrderHeadModel(),
        pagination: {
          skip: 0,
          take: 10,
          order: [{ key: 'order', value: 'ASC' }]
        }
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      getHead(data) {
        const oldspec = !this.firstLoading ? this.head.specifications.specification || [] : []
        this.head = new OrderHeadModel(data.OrderHead)
        this.setBreadcrumbs({ ...data.OrderHead, is_go_back: true })
        if (!this.firstLoading) {
          // const newDoc = new PaymentHeadModel(data.IncomingHead)
          if (!this.forwardDirection) {
            this.head.specifications.specification?.unshift(...oldspec)
          } else {
            const initialHeight = document.querySelector('.table-docs').scrollHeight
            this.head.specifications.specification?.push(...oldspec)
            if (oldspec.length > 0)
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
          }
        }

        this.firstLoading = false
      },
      async removeItems() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/removeOrderSpec.graphql'),
          variables: {
            input: {
              document_head: this.head.id,
              ids: this.selected.map((el) => el.id),
              isAllSelected: this.isAllSelected
            }
          }
        })

        if (data?.RemoveOrderSpec?.status) {
          this.selected = []
          this.$apollo.queries.OrderHead.refresh()
        }
      },
      async scrollDown() {
        const lastItem = this.head.specifications.specification?.[this.head.specifications.specification?.length - 1]
        if (!lastItem) return
        const pagination = this.pagination
        this.forwardDirection = false
        pagination.skip = lastItem.int_order
        pagination.take = this.pagination.take
        if (pagination.skip >= this.head.specifications.total) {
          return
        }
        this.pagination = pagination
        await this.fetchHead(pagination)
        this.pagination.take = 10
      },
      async scrollUp() {
        const firstElement = this.head.specifications.specification?.[0]
        if (!firstElement || firstElement?.int_order <= 2) return
        const pagination = Object.assign(this.pagination)
        this.forwardDirection = true
        pagination.skip = firstElement.int_order - pagination.take - 2
        if (pagination.skip < 0) {
          pagination.take = pagination.take + pagination.skip
          pagination.skip = 0
        }
        this.fetchHead(pagination)
      },
      async fetchHead(pagination) {
        const { data } = await this.$apollo.query({
          query: require('../gql/orderHead.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.$route.query.id ? this.$route.query.id : this.$route.params.id
            },
            pagination: pagination
          }
        })
        if (data) this.getHead(data)
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      addProduct(val, prod) {
        if (val) this.selected = [...this.selected, prod]
        else {
          this.selected = this.selected?.filter((el) => el.id !== prod.id)
        }
      },
      async getByOrder(spec, cb) {
        const pagination = this.pagination
        pagination.skip = spec.int_order - 2
        if (pagination.skip > this.head.specifications.total - pagination.take)
          pagination.skip = this.head.specifications.total - pagination.take
        this.first = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      scroll_to(item) {
        setTimeout(() => {
          this.$refs['delta' + item.specification.id].showInput()
        }, 100)
        document.querySelector('.table-docs').scrollTo({
          top: this.$refs['delta' + item.specification.id].$el.offsetTop - 80
        })
      },
      async select_product(product) {
        if (product.specification) {
          if (!this.$refs['delta' + product.specification.id]) {
            this.getByOrder(product.specification, () => this.scroll_to(product))
          } else {
            this.scroll_to(product)
          }

          return
        }

        this.createSpec(product)
      },
      async createSpec(product) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/createOrderSpec.graphql'),
          variables: {
            input: {
              product: product.id,
              document_head: this.head.id
            }
          }
        })

        await this.getLast(() => {
          setTimeout(() => {
            document.querySelector('.table-docs').scrollTo({
              top: document.querySelector('.table-docs').scrollHeight,
              left: 0
            })
          }, 100)
          this.$nextTick(() => {
            this.$refs['delta' + data.CreateOrderSpec.id].showInput()
          })
        })

        this.$noty.show('Добавлено')

        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
      },
      async getLast(cb) {
        this.forwardDirection = true
        const pagination = Object.assign(this.pagination)
        pagination.skip = this.head.specifications.total - pagination.take + 1
        if (pagination.skip < 0) pagination.skip = 0
        this.firstLoading = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      setDelta(val, item, isEnter) {
        item.setDelta(val)
        if (isEnter) document.getElementById(`price${item.id}`).click()
        this.updateSpec(item)
      },
      setPrice(val, item) {
        item.setPrice(val)
        this.updateSpec(item)
      },
      setMarkup(val, item) {
        item.setMarkup(val)
        this.updateSpec(item)
      },
      async updateSpec(spec) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/createOrderSpec.graphql'),
            variables: {
              input: { ...spec.input, document_head: this.head.id }
            }
          })
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка обновления спецификации')
        }
      },
      async updateHead() {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: require('../gql/updateHead.graphql'),
            variables: {
              input: this.head.input
            }
          })
        }, 500)
      },
      async fixDocument(fixed) {
        try {
          this.loading = true
          const { data } = await this.$apollo.mutate({
            mutation: require('../gql/ChangeOrderStatus.graphql'),
            variables: {
              input: {
                fixed,
                id: this.head.id
              }
            }
          })

          if (data?.FixOrder?.status) {
            if (fixed) this.$router.back()
            this.firstLoading = true
            this.$apollo.queries.OrderHead.refresh()
          }
        } catch (e) {
          console.error(e)
          this.loading = false
          this.$noty.error(e?.graphQLErrors?.[0]?.extensions?.ru ?? 'Ошибка')
        }
        this.loading = false
      },
      setSelected(val) {
        if (val) this.selected = this.head.specifications.specification
        else this.selected = []
      },
      getRemainders(item) {
        let sum = 0
        if (this.head.storage?.id) {
          return item.product.remainders?.find((el) => el.storage?.id === this.head.storage.id)?.value || 0
        }
        item.product.remainders?.forEach((el) => (sum += el.value))
        return sum
      },
      async printDoc() {
        try {
          const { data } = await http.get(`templates/order?order=${this.head.id}`)
          if (data) {
            print_html(data)
          } else {
            this.$noty.error('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка печати')
        }
      }
    },

    watch: {
      selected(val) {
        this.isAllSelected = val.length === this.head.specifications.specification.length
      },
      head() {
        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            const table = document.querySelector('.table-docs')
            if (table.scrollHeight === table.clientHeight) {
              this.scrollDown()
            }
          })
        }, 100)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #order-document {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
      min-height: 1px;
    }

    .b-action-buttons {
      button {
        min-width: 87px;
        justify-content: center;
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    table {
      tr {
        th {
          border-bottom: 1px solid #e1e1e1;
          border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1;
        }
      }
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100%;
      overflow-x: scroll;

      thead {
        position: sticky;
        top: -1px;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-top: none;
        }
      }

      tr {
        th.b-checkbox {
          padding: 10px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }

      @media (max-height: 960px) {
        tr {
          td {
            &.td-name {
              padding: 5px 16px;

              .b-name.article {
                line-height: 17px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;

        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      table {
        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }

          th {
            padding: 16px !important;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    .b-search {
      &__wrapper {
        width: 100%;
        z-index: 10;

        & > div {
          position: fixed;
          z-index: 10;

          & > div {
            position: fixed;
          }
        }
      }

      &-result {
        position: absolute;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 320px;
        overflow-y: auto;
        z-index: 10000;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }
        }
      }
    }

    .count {
      position: relative;
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }
  }

  #order-document {
    .flex-card {
      flex-grow: 1;
      min-height: 1px;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 1px;

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-docs {
      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;

        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .b-search {
      &__wrapper {
        & > div {
          position: fixed;
          z-index: 10;

          & > div {
            position: fixed;
          }
        }
      }

      &-result {
        .item {
          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .count {
      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
</style>
