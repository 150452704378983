<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  disabled
                  :clearable="false"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Поставщик"
              label-for="supplier"
              :disabled="fixed"
            >
              <template v-if="fixed">
                {{ filter_params.supplier?.name }}
              </template>
              <template v-else>
                <i-select
                  id="supplier"
                  :value="filter_params.supplier?.id"
                  transfer
                  :disabled="fixed"
                  @on-change="setSupplier"
                >
                  <i-option
                    v-for="item in supplier_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Место хранения"
              label-for="storage"
            >
              <template v-if="fixed">
                {{ filter_params?.storage?.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.storage?.id"
                  filterable
                  transfer
                  @on-change="changeStorage"
                >
                  <i-option
                    v-for="item in storage_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата поставки"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  :disabled="fixed"
                  :clearable="false"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.supply_date"
                  format="dd.MM.yyyy"
                  :options="dateOptions"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    :disabled="fixed"
                    @input="updateHead"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { OrderHeadModel } from '@/views/order/model/order-head.model'
  import { mapGetters } from 'vuex'

  export default {
    name: 'RevaluationFilters',
    apollo: {
      Suppliers: {
        query: require('../../operational-processes/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Suppliers ?? []
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
          if (!this.filter_params.storage.id) this.filter_params.setStorage(data.Storages?.[0])
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      filter_params: {
        type: OrderHeadModel,
        default: () => new OrderHeadModel()
      },
      fixed: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        supplier_list: [],
        storage_list: [],
        date: this.filter_params.date,
        debounce: null,
        dateOptions: {
          disabledDate(date) {
            return date.valueOf() < Date.now() - 86400000
          }
        }
      }
    },
    methods: {
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      updateHead() {
        this.$emit('change')
      },
      changeStorage(val) {
        const str = this.storage_list.find((el) => el.id === val)
        this.filter_params.setStorage(str)
        this.$emit('change')
      },
      setSupplier(val) {
        const str = this.supplier_list.find((el) => el.id === val)
        this.filter_params.setSupplier(str)
        this.$emit('change')
      },
      setComment(val) {
        this.filter_params.setComment(val)
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setDate(val) {
        this.filter_params.setSupplyDate(val)
        this.$emit('change')
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped>
  .params-body {
    padding-top: 16px;
  }
</style>
